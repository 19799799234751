import React, { useState } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { makeStyles } from "@material-ui/core/styles"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"

import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"

import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import LocalHospitalIcon from "@material-ui/icons/LocalHospital"
import FacebookIcon from "@material-ui/icons/Facebook"
import NoteIcon from "@material-ui/icons/Note"
import ClassIcon from "@material-ui/icons/Class"
import PeopleIcon from "@material-ui/icons/People"

import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    margin: "20px 0px 20px 0px",
  },
  descriptionContainer: {
    margin: "20px 0px 20px 0px",
  },
  section: {
    position: "relative",
    maxWidth: "600px",
    margin: "auto",
  },
  imageOverlay: {
    position: "absolute",
    backgroundColor: "rgba(8,25,60,.8)",
    width: "100%",
    height: "100%",
    zIndex: 2,
  },
  image: {
    zIndex: 1,
  },
  sectionContainer: {
    top: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flexbox",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    zIndex: 3,
  },
  sectionHeader: {},
  sectionDescription: {
    margin: "30px 0 30px 0",
  },
  sectionButton: {},
  bannerImage: {
    width: "500",
    height: "500",
  },
  instructorsButton: {
    margin: "20px 0px 32px 0px",
  },
  contactUsLinks: {
    color: "white",
  },
  senseiAdviceImages: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  joinKSKImages: {
    margin: "auto",
  },
})

export default function MainBody() {
  const classes = useStyles()
  const [notice, setNotice] = useState(true)

  const data = useStaticQuery(
    graphql`
      {
        visit1: file(relativePath: { eq: "visit-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        visit2: file(relativePath: { eq: "visit-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        senseiAdvice1: file(relativePath: { eq: "sensei-advice1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        senseiAdvice2: file(relativePath: { eq: "sensei-advice2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        senseiAdvice3: file(relativePath: { eq: "sensei-advice3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        camp1: file(relativePath: { eq: "camp1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        camp2: file(relativePath: { eq: "camp2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        ramenya: file(relativePath: { eq: "ramenya.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        ramenya2: file(relativePath: { eq: "ramenya2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        halloween: file(relativePath: { eq: "halloween.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        fireworks: file(relativePath: { eq: "fireworks.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  )

  return (
    <Container className={classes.root} maxWidth="lg">
      <Hidden xsDown>
        <Typography variant="h2" align="center" color="primary">
          BE STRONG, BE TRUE, BE BEAUTIFUL
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography
          className={classes.motto}
          variant="h4"
          align="center"
          color="primary"
        >
          BE STRONG,
          <br />
          BE TRUE,
          <br />
          BE BEAUTIFUL
        </Typography>
      </Hidden>
      <Typography variant="subtitle2" align="center" color="secondary">
        ( ken • shin • kai )
      </Typography>
      <Container>
        <Typography
          className={classes.descriptionContainer}
          variant="body1"
          align="center"
        >
          New York Kenshinkai is located right in the heart of Manhattan. Having
          celebrated our fifteenth year in 2017, we have many dedicated members
          who are pursuing both their individual and common goals through the
          practice of Kendo. We are affiliated with the national All US Kendo
          Federation and the regional All Eastern US Kendo Federation. If you
          happen to be in New York, we would be very happy to have you visit us.
          <br />
          <br />
          Please visit our Facebook page for the latest updates.
        </Typography>
      </Container>
      <Container align="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="https://www.facebook.com/NYKenshinkai/"
          startIcon={<FacebookIcon />}
          endIcon={<NavigateNextIcon />}
        >
          Facebook
        </Button>
      </Container>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="secondary"
      >
        Visit Our Dojo
      </Typography>
      <Container>
        <Typography
          className={classes.descriptionContainer}
          variant="body1"
          align="center"
        >
          Classes are tailored to the attendees' experience level so everyone
          can have a meaningful practice.
          <br />
          Never feel left behind.
        </Typography>
      </Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} zeroMinWidth>
          <div className={classes.section}>
            <div className={classes.imageOverlay} />
            <Img fluid={data.visit1.childImageSharp.fluid} />
            <Grid container className={classes.sectionContainer}>
              <Grid item className={classes.sectionHeader}>
                <Typography
                  className={classes.sectionHeader}
                  variant="h5"
                  align="center"
                >
                  Beginners
                </Typography>
              </Grid>
              <Grid item className={classes.sectionDescriptionContainer}>
                <Typography
                  className={classes.sectionDescription}
                  variant="body1"
                  align="center"
                >
                  Beginners are welcome to visit and observe classes.
                  <br />
                  <Link className={classes.contactUsLinks} to="/contact-us/">
                    Please contact us in advance.
                  </Link>
                </Typography>
              </Grid>
              <Grid item className={classes.sectionButton}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/beginners-guide/"
                  startIcon={<NoteIcon />}
                  endIcon={<NavigateNextIcon />}
                >
                  Beginner's Guide
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} zeroMinWidth>
          <div className={classes.section}>
            <div className={classes.imageOverlay} />
            <Img fluid={data.visit2.childImageSharp.fluid} />
            <Grid container className={classes.sectionContainer}>
              <Grid item className={classes.sectionHeader}>
                <Typography
                  className={classes.sectionHeader}
                  variant="h5"
                  align="center"
                >
                  Advanced
                </Typography>
              </Grid>
              <Grid item className={classes.sectionDescriptionContainer}>
                <Typography
                  className={classes.sectionDescription}
                  variant="body1"
                  align="center"
                >
                  Friday classes are open to advanced visitors.
                  <br />
                  <Link className={classes.contactUsLinks} to="/contact-us/">
                    Please contact us in advance.
                  </Link>
                </Typography>
              </Grid>
              <Grid item className={classes.sectionButton}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/class-information/"
                  startIcon={<ClassIcon />}
                  endIcon={<NavigateNextIcon />}
                >
                  Class Information
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="secondary"
      >
        Meet Our Instructors
      </Typography>
      <Container>
        <Typography
          className={classes.descriptionContainer}
          variant="body1"
          align="center"
        >
          Our instructors are passionate about Kendo. Most instructors have over
          20 years of kendo experience and have achieved an average rank of 6th
          Dan.
          <br />
          They are committed to helping you improve.
        </Typography>
      </Container>
      <Container align="center" className={classes.instructorsButton}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/instructors/"
          startIcon={<PeopleIcon />}
          endIcon={<NavigateNextIcon />}
        >
          Instructors
        </Button>
      </Container>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.senseiAdvice1.childImageSharp.fluid}
            className={classes.senseiAdviceImages}
          />
        </Grid>
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.senseiAdvice2.childImageSharp.fluid}
            className={classes.senseiAdviceImages}
          />
        </Grid>
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.senseiAdvice3.childImageSharp.fluid}
            className={classes.senseiAdviceImages}
          />
        </Grid>
      </Grid>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="secondary"
      >
        Join NY Kenshinkai
      </Typography>
      <Container>
        <Typography
          className={classes.descriptionContainer}
          variant="body1"
          align="center"
        >
          In addition to attending and hosting many kendo events throughout the
          year, we also have many casual events where we further strengthen our
          bonds outside of practice. One of our long-time traditions is an
          annual "no kendo" summer camp.
        </Typography>
        <Typography
          className={classes.descriptionContainer}
          variant="h6"
          align="center"
        >
          Let's have fun together!
        </Typography>
      </Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.ramenya.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.halloween.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
        <Grid item xs={12} md={4} zeroMinWidth>
          <Img
            fluid={data.ramenya2.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
        <Grid item xs={12} md={3} zeroMinWidth>
          <Img
            fluid={data.fireworks.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
        <Grid item xs={12} md={6} zeroMinWidth>
          <Img
            fluid={data.camp2.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
        <Grid item xs={12} md={3} zeroMinWidth>
          <Img
            fluid={data.camp1.childImageSharp.fluid}
            className={classes.joinKSKImages}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
