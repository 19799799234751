import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import MainBody from "../components/main-body"

const IndexPage = ({ data }) => (
  <>
    <SEO title="Be Strong, Be True, Be Beautiful" />
    <Img
      fluid={data.cover.childImageSharp.fluid}
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      }}
    />
    <MainBody />
  </>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default IndexPage
